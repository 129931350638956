const AppRoutes = {
  LOGIN: "/",
  SIGNUP: "/signup",
  DASHBOARD: "/",
  SETTINGS: "/settings",

  // products
  PRODUCTS: "/products",
  INVENTORY: "/inventory",
  PRODUCTSDETAILS: "/products/details",

  // customer
  CUSTOMER: "/customer",
  CUSTOMERDETAILS: "/customer/details",

  // report
  SALESREPORT: "/sales-report",
  SALESORDEREPORT: "/sales-order-report",
  ViewSALESOrderReport: "/view-sales-order-report",
  ViewSALESReport: "/view-sales-report",
  SALESREPORTBYCUSTOMER: "/sales-report-by-customer",
  ViewSALESReportByCustomer: "/view-sales-report-by-customer",
  SALESREPORTBYREP: "/sales-report-by-repr",
  ViewSALESReportBYREP: "/view-sales-report-by-repr",
  PURCHASEREPORT: "/purchase-report",
  ViewPURCHASEReport: "/view-purchase-report",
  PURCHASEREPORTBYVENDOR: "/purchase-report-by-vendor",
  ViewPURCHASEReportByVendor: "/view-purchase-report-by-vendor",

  // vendor
  VENDOR: "/vendor",
  VENDORDETAILS: "/vendor/details",

  // purchase-order
  PurchaseOrder: "/purchase-order",
  PurchaseOrderCreate: "/purchase-order/create",
  PurchaseOrderUpdate: "/purchase-order/update",
  PurchaseOrderDetails: "/purchase-order/details",

  // sales-order
  SalesOrder: "/sales-order",
  SalesOrderCreate: "/sales-order/create",
  SalesOrderUpdate: "/sales-order/update",
  SalesOrderDetails: "/sales-order/details",

  // invoice
  Invoice: "/invoice",
  ViewInvoice: "/view-invoice",
  ViewDeleveryNotes: "/invoice-delevery-notes",
  ServiceTagView: "/view-service-tags",
  InvoiceCreate: "/invoice/create",
  InvoiceUpdate: "/invoice/update",
  InvoiceDetails: "/invoice/details",

    // invoice without Tax
    InvoiceWT: "/invoice-without-tax",
    ViewInvoiceWT: "/view-invoice-without-tax",
    ViewDeleveryNotesWT: "/invoiceWT-delevery-notes",
    InvoiceCreateWT: "/invoice-without-tax/create",
    InvoiceUpdateWT: "/invoice-without-tax/update",
    InvoiceDetailsWT: "/invoice-without-tax/details",

  // settings routes
  PERMISSIONS: "permissions",
  SCREEN_ACCESS: "screen-access",
  ROLES: "roles",
  SCREENS: "screens",
  TEAM: "team",
  CURRENCY:"currency",
  PAYMENTMETHOD:"payment-method",
  SHIPPINGMETHOD:"shipping-method"
};

export default AppRoutes;
