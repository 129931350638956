import { Route } from "react-router-dom";
import AppRoutes from "navigation/appRoutes";
import * as UserLoadable from "../loadRoutes/user.load";

const customerRouting = (
  <>
  <Route element={<UserLoadable.Customer />} path={AppRoutes.CUSTOMER} />
  <Route
      element={<UserLoadable.CustomerDetails />}
      path={`${AppRoutes.CUSTOMERDETAILS}/:customerId`}
    />
  </>
);

export default customerRouting;
