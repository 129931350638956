/* eslint-disable import/no-extraneous-dependencies */
import Routes from "navigation";
import "./App.css";


function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
