import AppRoutes from "navigation/appRoutes";
import { Route } from "react-router-dom";
import * as InvoiceLoadable from "../loadRoutes/user.load";

// invoice without tax

const invoiceWTRouting = (
  <>
    <Route element={<InvoiceLoadable.InvoiceWT />} path={AppRoutes.InvoiceWT} />
    <Route
      element={<InvoiceLoadable.InvoiceViewWT />}
      path={`${AppRoutes.ViewInvoiceWT}/:invoiceId`}
    />
      <Route
      element={<InvoiceLoadable.DeleveryNotesViewWT />}
      path={`${AppRoutes.ViewDeleveryNotesWT}/:invoiceId`}
    />
    <Route
      element={<InvoiceLoadable.InvoiceCreateWT />}
      path={AppRoutes.InvoiceCreateWT}
    />
    <Route
      element={<InvoiceLoadable.InvoiceCreateWT />}
      path={`${AppRoutes.InvoiceUpdateWT}/:updateId`}
    />
    <Route
      element={<InvoiceLoadable.InvoiceDetailsWT />}
      path={`${AppRoutes.InvoiceDetailsWT}/:orderId`}
    />
  </>
);

export default invoiceWTRouting;
