import { CircularProgress, Stack } from "@mui/material";

interface ISpinner {
  height?: number | string;
}

function Spinner(props: ISpinner) {
  const { height } = props;
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="center"
      sx={{ height: height || "100vh", width: "100%" }}
    >
      <CircularProgress color="success" size={22} />
    </Stack>
  );
}

export default Spinner;
