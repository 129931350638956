import { Spinner } from "components/loader";
import Loadable from "react-loadable";

export const Products = Loadable({
  loader: () => import("pages/user/products"),
  loading: () => <Spinner />,
});

export const Inventory = Loadable({
  loader: () => import("pages/user/products/Inventory/Invendory"),
  loading: () => <Spinner />,
});

export const ProductsDetails = Loadable({
  loader: () => import("pages/user/products/product-details"),
  loading: () => <Spinner />,
});

export const Customer = Loadable({
  loader: () => import("pages/user/customer"),
  loading: () => <Spinner />,
});
export const CustomerDetails = Loadable({
  loader: () => import("pages/user/customer/customer-details"),
  loading: () => <Spinner />,
});

export const Vendor = Loadable({
  loader: () => import("pages/user/vendors"),
  loading: () => <Spinner />,
});
export const VendorDetails = Loadable({
  loader: () => import("pages/user/vendors/vendor-details"),
  loading: () => <Spinner />,
});

export const PurchaseOrder = Loadable({
  loader: () => import("pages/user/purchase-order"),
  loading: () => <Spinner />,
});

export const PurchaseOrderCreate = Loadable({
  loader: () =>
    import("pages/user/purchase-order/component/PurchaseOrderCreate"),
  loading: () => <Spinner />,
});

export const PurchaseOrderDetails = Loadable({
  loader: () => import("pages/user/purchase-order/purchase-order-details"),
  loading: () => <Spinner />,
});

export const SalesOrder = Loadable({
  loader: () => import("pages/user/sales-order"),
  loading: () => <Spinner />,
});

export const SalesOrderCreate = Loadable({
  loader: () => import("pages/user/sales-order/component/SalesOrderCreate"),
  loading: () => <Spinner />,
});

export const SalesOrderDetails = Loadable({
  loader: () => import("pages/user/sales-order/sales-order-details"),
  loading: () => <Spinner />,
});

export const Invoice = Loadable({
  loader: () => import("pages/user/invoice"),
  loading: () => <Spinner />,
});

export const InvoiceCreate = Loadable({
  loader: () => import("pages/user/invoice/component/InvoiceCreate"),
  loading: () => <Spinner />,
});

export const InvoiceDetails = Loadable({
  loader: () => import("pages/user/invoice/invoice-details"),
  loading: () => <Spinner />,
});

export const InvoiceView = Loadable({
  loader: () => import("pages/user/invoice/component/ViewInvoice"),
  loading: () => <Spinner />,
});

export const DeleveryNotesView = Loadable({
  loader: () => import("pages/user/invoice/component/ViewDeleveryNotes"),
  loading: () => <Spinner />,
});

export const ServiceTagView = Loadable({
  loader: () => import("pages/user/invoice/component/ViewServiceTags"),
  loading: () => <Spinner />,
});

// invoice Withoudt Tax

export const InvoiceWT = Loadable({
  loader: () => import("pages/user/invoice-wt"),
  loading: () => <Spinner />,
});

export const InvoiceCreateWT = Loadable({
  loader: () => import("pages/user/invoice-wt/component/InvoiceCreate"),
  loading: () => <Spinner />,
});

export const InvoiceDetailsWT = Loadable({
  loader: () => import("pages/user/invoice-wt/invoice-details"),
  loading: () => <Spinner />,
});

export const InvoiceViewWT = Loadable({
  loader: () => import("pages/user/invoice-wt/component/ViewInvoice"),
  loading: () => <Spinner />,
});

export const DeleveryNotesViewWT = Loadable({
  loader: () => import("pages/user/invoice-wt/component/ViewDeleveryNotes"),
  loading: () => <Spinner />,
});

export const PurchaseReports = Loadable({
  loader: () => import("pages/user/purchase-order/purchase-report"),
  loading: () => <Spinner />,
});

export const ViewPurchaseReports = Loadable({
  loader: () =>
    import(
      "pages/user/purchase-order/purchase-report/ReportGenerate/PurchaseReportGenerate"
    ),
  loading: () => <Spinner />,
});

export const PurchaseReportsByVendor = Loadable({
  loader: () => import("pages/user/purchase-order/purchase-report-by-vendor"),
  loading: () => <Spinner />,
});

export const ViewPurchaseReportsByVendor = Loadable({
  loader: () =>
    import(
      "pages/user/purchase-order/purchase-report-by-vendor/ReportGenerate/PurchaseReportGenerate"
    ),
  loading: () => <Spinner />,
});

export const SalesReports = Loadable({
  loader: () => import("pages/user/invoice/sales-reports"),
  loading: () => <Spinner />,
});

export const SalesOrderReports = Loadable({
  loader: () => import("pages/user/invoice/sales-order-reports"),
  loading: () => <Spinner />,
});
export const ViewSALESOrderReport = Loadable({
  loader: () =>
    import(
      "pages/user/invoice/sales-order-reports/ReportGenerate/SalesReportGenerate"
    ),
  loading: () => <Spinner />,
});

export const ViewSalesReports = Loadable({
  loader: () =>
    import(
      "pages/user/invoice/sales-reports/ReportGenerate/SalesReportGenerate"
    ),
  loading: () => <Spinner />,
});

export const SalesReportsByCusomer = Loadable({
  loader: () => import("pages/user/invoice/sales-reports-by-customer"),
  loading: () => <Spinner />,
});

export const ViewSalesReportsByCusomer = Loadable({
  loader: () =>
    import(
      "pages/user/invoice/sales-reports-by-customer/ReportGenerate/SalesReportGenerate"
    ),
  loading: () => <Spinner />,
});
export const SalesReportsByRep = Loadable({
  loader: () => import("pages/user/invoice/sales-reports-by-rep"),
  loading: () => <Spinner />,
});

export const ViewSalesReportsByRep = Loadable({
  loader: () =>
    import(
      "pages/user/invoice/sales-reports-by-rep/ReportGenerate/SalesReportGenerate"
    ),
  loading: () => <Spinner />,
});
