import AppRoutes from "navigation/appRoutes";
import { Route } from "react-router-dom";
import * as PurchaseLoadable from "../loadRoutes/user.load";

const purchaseRouting = (
  <>
    <Route
      element={<PurchaseLoadable.PurchaseOrder />}
      path={AppRoutes.PurchaseOrder}
    />
    <Route
      element={<PurchaseLoadable.PurchaseOrderCreate />}
      path={AppRoutes.PurchaseOrderCreate}
    />
    <Route
      element={<PurchaseLoadable.PurchaseOrderCreate />}
      path={`${AppRoutes.PurchaseOrderUpdate}/:updateId`}
    />

    <Route
      element={<PurchaseLoadable.PurchaseOrderDetails />}
      path={`${AppRoutes.PurchaseOrderDetails}/:orderId`}
    />
  </>
);

export default purchaseRouting;
