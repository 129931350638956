import AppRoutes from "navigation/appRoutes";
import { Route } from "react-router-dom";
import * as VendorLoadable from "../loadRoutes/user.load";

const vendorRouting = (
  <>
    <Route element={<VendorLoadable.Vendor />} path={AppRoutes.VENDOR} />
    <Route
      element={<VendorLoadable.VendorDetails />}
      path={`${AppRoutes.VENDORDETAILS}/:vendorId`}
    />
  </>
);

export default vendorRouting;
