import AppRoutes from "navigation/appRoutes";
import { Route } from "react-router-dom";
import * as InvoiceLoadable from "../loadRoutes/user.load";

const invoiceRouting = (
  <>
    <Route element={<InvoiceLoadable.Invoice />} path={AppRoutes.Invoice} />
    <Route
      element={<InvoiceLoadable.InvoiceView />}
      path={`${AppRoutes.ViewInvoice}/:invoiceId`}
    />
      <Route
      element={<InvoiceLoadable.DeleveryNotesView />}
      path={`${AppRoutes.ViewDeleveryNotes}/:invoiceId`}
    />
    <Route
      element={<InvoiceLoadable.ServiceTagView />}
      path={`${AppRoutes.ServiceTagView}/:invoiceId`}
    />
    <Route
      element={<InvoiceLoadable.InvoiceCreate />}
      path={AppRoutes.InvoiceCreate}
    />
    <Route
      element={<InvoiceLoadable.InvoiceCreate />}
      path={`${AppRoutes.InvoiceUpdate}/:updateId`}
    />
    <Route
      element={<InvoiceLoadable.InvoiceDetails />}
      path={`${AppRoutes.InvoiceDetails}/:orderId`}
    />
  </>
);

export default invoiceRouting;
