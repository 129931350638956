import { Route } from "react-router-dom";
import * as UserLoadable from "../loadRoutes/user.load";
import AppRoutes from "../appRoutes";

const productRouting = (
  <>
    <Route element={<UserLoadable.Products />} path={AppRoutes.PRODUCTS} />
    <Route element={<UserLoadable.Inventory />} path={AppRoutes.INVENTORY} />
    <Route
      element={<UserLoadable.ProductsDetails />}
      path={`${AppRoutes.PRODUCTSDETAILS}/:productId`}
    />
  </>
);

export default productRouting;
