import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IProductForm } from "pages/user/products/hooks/useProductForm";
import { IProductsResponseData } from "pages/user/products/types/IProductsResponseRoot";
import { useAppDispatch } from "redux/store";

interface IProductReducer {
  products: any[];
}

const initialState: IProductReducer = {
  products: [],
};

interface IPayload {
  product: any;
}

interface IActionPayload {
  payload: IPayload;
  type: string;
}

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProducts: (
      state,
      action: PayloadAction<{ product: IProductsResponseData }>,
    ) => {
      console.log("checkNew", state.products)
      const findProduct = state.products?.find(
        (i) => i?.id === action?.payload?.product?.id,
      );
      console.log("findProduct", findProduct);
      const filterData = state.products?.filter(
        (i) => i !== action?.payload?.product?.id,
      );
      if (!findProduct) {
        let newState = state;
        newState.products = filterData
      } else {
        state.products?.push(action?.payload?.product);
      }
    },
  },
});

const { setProducts } = productSlice.actions;

export const useProductActions = () => {
  const dispatch = useAppDispatch();
  return {
    setProducts: (payload: IPayload) => dispatch(setProducts(payload)),
  };
};
const { reducer } = productSlice;

export default reducer;
