import useUserInfo from "hooks/useUserInfo";
import { Outlet, Route, Routes } from "react-router-dom";
import PermissionsLayout from "./PermissionProtect";
import ProtectedRoute from "./ProtectedRoute";
import SettingPermissionsLayout from "./SettingPermissionsLayout";
import AppRoutes from "./appRoutes";
import * as AuthLoadable from "./loadRoutes/auth.load";
import * as SettingsLoadable from "./loadRoutes/settings.load";
import * as AdminLoadable from "./loadRoutes/admin.load";
import * as UserLoadable from "./loadRoutes/user.load";
import productRouting from "./routings/product.routing";
import customerRouting from "./routings/customer.routing";
import vendorRouting from "./routings/vendor.routing";
import purchaseRouting from "./routings/purchase.routing";
import salesRouting from "./routings/sales.routing";
import invoiceRouting from "./routings/invoice.routing";
import reportsRouting from "./routings/report.routing";
import invoiceWTRouting from "./routings/invoice-wt.routing"

function Application() {
  const { isLoggedIn } = useUserInfo();
  return (
    <Routes>
      {isLoggedIn ? (
        <Route element={<ProtectedRoute />}>
          <Route element={<PermissionsLayout />}>
            <Route
              element={<AdminLoadable.Dashboard />}
              path={AppRoutes.DASHBOARD}
            />
            {productRouting}
            {customerRouting}
            {vendorRouting}
            {purchaseRouting}
            {salesRouting}
            {invoiceRouting}
            {invoiceWTRouting}
            {reportsRouting}

            <Route
              element={<SettingPermissionsLayout />}
              path={AppRoutes.SETTINGS}
            >
              <Route
                index
                element={<SettingsLoadable.Settings />}
                path={AppRoutes.SETTINGS}
              />
              <Route
                element={<SettingsLoadable.Permissions />}
                path={AppRoutes.PERMISSIONS}
              />
              <Route
                element={<SettingsLoadable.ScreenAccess />}
                path={AppRoutes.SCREEN_ACCESS}
              />
              <Route
                element={<SettingsLoadable.Roles />}
                path={AppRoutes.ROLES}
              />

              <Route
                element={<SettingsLoadable.Screens />}
                path={AppRoutes.SCREENS}
              />
              <Route
                element={<SettingsLoadable.Team />}
                path={AppRoutes.TEAM}
              />
              <Route
                element={<SettingsLoadable.Currency />}
                path={AppRoutes.CURRENCY}
              />
              <Route
                element={<SettingsLoadable.PaymentMethod />}
                path={AppRoutes.PAYMENTMETHOD}
              />
              <Route
                element={<SettingsLoadable.ShippingMethod />}
                path={AppRoutes.SHIPPINGMETHOD}
              />
            </Route>
          </Route>
        </Route>
      ) : null}
      <Route element={<AuthLoadable.Login />} path={AppRoutes.LOGIN} />
      <Route element={<AuthLoadable.Signup />} path={AppRoutes.SIGNUP} />
    </Routes>
  );
}

export default Application;
