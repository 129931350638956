import AppRoutes from "navigation/appRoutes";
import { Route } from "react-router-dom";
import * as ReportLoadable from "../loadRoutes/user.load";

const reportRouting = (
  <>
    <Route
      element={<ReportLoadable.SalesReports />}
      path={AppRoutes.SALESREPORT}
    />
     <Route
      element={<ReportLoadable.SalesOrderReports />}
      path={AppRoutes.SALESORDEREPORT}
    />
    <Route
      element={<ReportLoadable.ViewSalesReports />}
      path={`${AppRoutes.ViewSALESReport}/:data`}
    />
    <Route
      element={<ReportLoadable.ViewSALESOrderReport />}
      path={`${AppRoutes.ViewSALESOrderReport}/:data`}
    />

    <Route
      element={<ReportLoadable.SalesReportsByCusomer />}
      path={AppRoutes.SALESREPORTBYCUSTOMER}
    />
    <Route
      element={<ReportLoadable.ViewSalesReportsByCusomer />}
      path={`${AppRoutes.ViewSALESReportByCustomer}/:data`}
    />

    <Route
      element={<ReportLoadable.SalesReportsByRep />}
      path={AppRoutes.SALESREPORTBYREP}
    />
    <Route
      element={<ReportLoadable.ViewSalesReportsByRep />}
      path={`${AppRoutes.ViewSALESReportBYREP}/:data`}
    />

    <Route
      element={<ReportLoadable.PurchaseReports />}
      path={AppRoutes.PURCHASEREPORT}
    />
    <Route
      element={<ReportLoadable.ViewPurchaseReports />}
      path={`${AppRoutes.ViewPURCHASEReport}/:data`}
    />
    <Route
      element={<ReportLoadable.PurchaseReportsByVendor />}
      path={AppRoutes.PURCHASEREPORTBYVENDOR}
    />
    <Route
      element={<ReportLoadable.ViewPurchaseReportsByVendor />}
      path={`${AppRoutes.ViewPURCHASEReportByVendor}/:data`}
    />
  </>
);

export default reportRouting;
