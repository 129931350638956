import AppRoutes from "navigation/appRoutes";
import { Route } from "react-router-dom";
import * as SalesLoadable from "../loadRoutes/user.load";

const salesRouting = (
  <>
    <Route element={<SalesLoadable.SalesOrder />} path={AppRoutes.SalesOrder} />
    <Route
      element={<SalesLoadable.SalesOrderCreate />}
      path={AppRoutes.SalesOrderCreate}
    />
    <Route
      element={<SalesLoadable.SalesOrderCreate />}
      path={`${AppRoutes.SalesOrderUpdate}/:updateId`}
    />
    <Route
      element={<SalesLoadable.SalesOrderDetails />}
      path={`${AppRoutes.SalesOrderDetails}/:orderId`}
    />
  </>
);

export default salesRouting;
